import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,   
    postRequestFormData,
    postRequestadFormData,
    postRequestZipData,
} from "../coreFIles/helper";
// user login
export const LoginAction = (data) => {
  return postRequest("userLogin", data).then((res) => {
    return res.data;
  });
};

export const ForgotPasswordAction = (data) => {
  return postRequest("ForgotPassword", data).then((res) => {
    return res.data;
  });
};

export const ResetPasswordAction = (data) => {
  return postRequest("ResetPassword", data).then((res) => {
    return res.data;
  });
};

export const getGloabalSettingsAction = (data) => {
  return postRequest("getGloabalShippingSetting", data).then((res) => {
    return res.data;
  });
};

// user Profile
export const getUserProfileAction = (data) => {
  return postRequest("getUserProfile", data).then((res) => {
    return res.data;
  });
};

export const UpdateUserProfileAction = (data) => {
  return postRequest("UpdateUserProfile", data).then((res) => {
    return res.data;
  });
};

export const UpdateUserPasswordAction = (data) => {
  return postRequest("UpdateUserPassword", data).then((res) => {
    return res.data;
  });
};

export const getindustrylistAction = (data) => {
  return postRequest("getindustrylist", data).then((res) => {
    return res.data;
  });
};

export const getMonthlySpendlistAction = (data) => {
  return postRequest("getMonthlySpendlist", data).then((res) => {
    return res.data;
  });
};

export const getSearchSuburbAction = (data) => {
  return postRequest("getSearchSuburb", data).then((res) => {
    return res.data;
  });
};

export const RegisterUserAction = (data) => {
  return postRequest("RegisterNewUser", data).then((res) => {
    return res.data;
  });
};

// Get parent cats
export const getParentCatlistAction = (data) => {
  return postRequest("getParentCatlist", data).then((res) => {
    return res.data;
  });
};

export const getChildCatlistAction = (data) => {
  return postRequest("getChildCatlist", data).then((res) => {
    return res.data;
  });
};
export const getAllProductsListAction = (data) => {
  return postRequest("getAllProductsList", data).then((res) => {
    return res.data;
  });
};
export const getTotalAllProductsAction = (data) => {
  return postRequest("getTotalAllProducts", data).then((res) => {
    return res.data;
  });
};
export const getAllProductsCatsAction = (data) => {
  return postRequest("getAllProductsCats", data).then((res) => {
    return res.data;
  });
};

export const getmasterFilterAction = (data) => {
  return postRequest("getmasterFilter", data).then((res) => {
    return res.data;
  });
};

export const getFilterCountListAction = (data) => {
  return postRequest("getFilterCountList", data).then((res) => {
    return res.data;
  });
};
export const getUserPendingQuotesAction = (data) => {
  return postRequest("getUserPendingQuotes", data).then((res) => {
    return res.data;
  });
};
export const CreateNewQuoteAction = (data) => {
  return postRequest("CreateNewQuote", data).then((res) => {
    return res.data;
  });
};
export const addToCartProductAction = (data) => {
  return postRequest("addToCartProduct", data).then((res) => {
    return res.data;
  });
};
export const addToCartQRProductAction = (data) => {
  return postRequest("addToCartQRProduct", data).then((res) => {
    return res.data;
  });
};
export const UpdateCartProductAction = (data) => {
  return postRequest("UpdateCartProduct", data).then((res) => {
    return res.data;
  });
};

export const reOrderOrdersDetailsAction = (data) => {
  return postRequest("reOrderOrdersDetails", data).then((res) => {
    return res.data;
  });
};

export const UpdateCartProductQtyAction = (data) => {
  return postRequest("UpdateCartProductQty", data).then((res) => {
    return res.data;
  });
};
export const removeCartProductAction = (data) => {
  return postRequest("removeCartProduct", data).then((res) => {
    return res.data;
  });
};

export const getCartOrderSummeryAction = (data) => {
  return postRequest("getCartOrderSummery", data).then((res) => {
    return res.data;
  });
};

export const getUserfreeshippingInfoAction = (data) => {
  return postRequest("getUserfreeshippingInfo", data).then((res) => {
    return res.data;
  });
};

export const getAvailableStockAction = (data) => {
  return postRequest("getAvailableStock", data).then((res) => {
    return res.data;
  });
};

export const calculateShippingAction = (data) => {
  return postRequest("calculateShipping", data).then((res) => {
    return res.data;
  });
};
export const deleteUseraddressAction = (data) => {
  return postRequest("deleteUseraddress", data).then((res) => {
    return res.data;
  });
};
export const AddUserNewAddressAction = (data) => {
  return postRequest("AddUserNewAddress", data).then((res) => {
    return res.data;
  });
};

export const updateShipToAddressAction = (data) => {
  return postRequest("updateShipToAddress", data).then((res) => {
    return res.data;
  });
};
export const GetAddressDetailsAction = (data) => {
  return postRequest("GetAddressDetails", data).then((res) => {
    return res.data;
  });
};
export const UpdateAddressAction = (data) => {
  return postRequest("UpdateAddress", data).then((res) => {
    return res.data;
  });
};
export const PlaceNewOrderAction = (data) => {
  return postRequest("PlaceNewOrder", data).then((res) => {
    return res.data;
  });
};
export const PlaceCODOrderAction = (data) => {
  return postRequest("PlaceCODOrder", data).then((res) => {
    return res.data;
  });
};

export const ApplyCouponCodeAction = (data) => {
  return postRequest("ApplyCouponCode", data).then((res) => {
    return res.data;
  });
};

export const ValidateOrderAction = (data) => {
  return postRequest("ValidateOrder", data).then((res) => {
    return res.data;
  });
};


// checkout
export const getCartTotalsAction = (data) => {
  return postRequest("getCartTotals", data).then((res) => {
    return res.data;
  });
};

export const getUserOrdersListAction = (data) => {
  return postRequest("getUserOrdersListNew", data).then((res) => {
    return res.data;
  });
};
export const getUserPendingOrdersListAction = (data) => {
  return postRequest("getUserPendingOrdersList", data).then((res) => {
    return res.data;
  });
};
export const getUserCanceledOrdersListAction = (data) => {
  return postRequest("getUserCanceledOrdersList", data).then((res) => {
    return res.data;
  });
};

export const getUserOrdersDetailsAction = (data) => {
  return postRequest("getUserOrdersDetails", data).then((res) => {
    return res.data;
  });
};
export const getUserShipmentOrdersDetailsAction = (data) => {
  return postRequest("getUserShipmentOrdersDetails", data).then((res) => {
    return res.data;
  });
};

export const GetProductStockAction = (data) => {
  return postRequest("GetProductStock", data).then((res) => {
    return res.data;
  });
};

export const CancelOrderAction = (data) => {
  return postRequest("CancelOrder", data).then((res) => {
    return res.data;
  });
};
export const CreateOrderTemplateAction = (data) => {
  return postRequest("CreateOrderTemplate", data).then((res) => {
    return res.data;
  });
};
export const CreateNewOrderTemplateAction = (data) => {
  return postRequest("CreateNewOrderTemplate", data).then((res) => {
    return res.data;
  });
};
export const getUsersTempletsListAction = (data) => {
  return postRequest("getUsersTempletsList", data).then((res) => {
    return res.data;
  });
};
export const addTempleteToQuoteAction = (data) => {
  return postRequest("addTempleteToQuote", data).then((res) => {
    return res.data;
  });
};

export const deleteUserTemplateAction = (data) => {
  return postRequest("deleteUserTemplate", data).then((res) => {
    return res.data;
  });
};
export const logintoxeroAction = (data) => {
  return postRequest("logintoxero", data).then((res) => {
    return res.data;
  });
};

export const sendCertificatesEmailAction = (data) => {
  return postRequest("sendCertificatesEmail", data).then((res) => {
    return res.data;
  });
};
export const DownloadCertificateszipAction = (data) => {
  return postRequestZipData("DownloadCertificateszip", data).then((res) => {
    return res.data;
  });
};

export const updateProductStockAvailablityAction = (data) => {
  return postRequest("updateProductStockAvailablity", data).then((res) => {
    return res.data;
  });
};

export const getCanceledOrderItemsAction = (data) => {
  return postRequest("getCanceledOrderItems", data).then((res) => {
    return res.data;
  });
};

export const RedoCanceledOrderAction = (data) => {
  return postRequest("RedoCanceledOrder", data).then((res) => {
    return res.data;
  });
};

export const DeleteCanceledOrderAction = (data) => {
  return postRequest("DeleteCanceledOrder", data).then((res) => {
    return res.data;
  });
};

export const uploadCSVAction = (data) => {
  return postRequestFormData("uploadCSV", data).then((res) => {
    return res.data;
  });
};

export const getProductDetailAction = (data) => {
  return postRequest("getProductDetail", data).then((res) => {
    return res.data;
  });
};

export const getUserQuoteDetailsAction = (data) => {
  return postRequest("getUserQuoteDetails", data).then((res) => {
    return res.data;
  });
};
